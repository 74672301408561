import UserService from '../services/user.service';

const state = {
    load : false,
    mode : localStorage.getItem('mode'),
    list_country : [],
    list_agency : [],
    text_alert : ""
}

const getters = {};

const actions = {
    send_feedback({ commit }, payLoad) {     
        commit('setLoad', true)    
        return UserService.send_feedback(payLoad).then(
            res => {  
                commit('setLoad', false)    
                return Promise.resolve(res);
            },
            error => {       
                commit('setLoad', false)             
                return Promise.reject(error);
            }
        )
    },
    get_list_country({ commit }){
        return UserService.get_list_country().then(
            res => {
                commit('list_country', res);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        );
    }, 
    get_list_agency({ commit }){
        return UserService.get_list_agency().then(
            res => {
                commit('list_agency', res);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        );
    }, 
};
const mutations = {
    setLoad(state, res) {
        state.load = res;
    },
    setMode(state, res) {
        state.mode = res;
        localStorage.setItem('mode', res);
    },
    list_country(state, param){
        state.list_country = param
    },
    list_agency(state, param){
        state.list_agency = param
    },
    setTextAlert(state, param) {
        state.text_alert = param;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

