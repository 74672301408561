import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faPlus,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faSave,
  faTimes,
  faSearch,
  faTrash,
  faCheck,
  faFilePdf,
  faGlobeAmericas,
  faMinus
  
} from "@fortawesome/free-solid-svg-icons";

import {
  faLightbulb,
  faFilePdf as faFilePdfFar
} from "@fortawesome/free-regular-svg-icons";

library.add(faPlus, faChevronRight, faChevronDown, faChevronUp, faSave, faTimes, faSearch,
  faTrash, faLightbulb, faCheck, faFilePdf, faFilePdfFar, faGlobeAmericas, faMinus);

export { FontAwesomeIcon };
