import { Base64 } from 'js-base64'
import moment from 'moment'
moment.locale(localStorage.getItem('language'));

export const DDMMMYY = (date) => {
    if(!date){
        return "";
    }

    if(moment.isDate(date)){
        return moment(date).format('DD MMM YY') 
    }        
    else if(date.split('-').length === 3 && moment(date).format('DD MMM YY') != "Invalid date"){
        return moment(date).format('DD MMM YY') 
    }  
    else if((date.split('-').length === 2 || date.split('/').length === 2) && moment(date).format('MMM YY') != "Invalid date"){
        return moment(date).format('MMM YY')
    }        
    else if(date.split(' ').length === 2){
        let month_to_number = {
            "Janvier" : "0",
            "Février" : "1",
            "Mars" : "2",
            "Avril" : "3",
            "Mai" : "4",
            "Juin" : "5", 
            "Juillet" : "6",
            "Août" : "7", 
            "Septembre" : "8", 
            "Octobre" : "9", 
            "Novembre" : "10", 
            "Décembre" : "11"
        }
        let new_date = new Date(date.split(' ')[1], month_to_number[date.split(' ')[0]], 1)
        return moment(new_date).format('MMM YY') !== "Invalid date" ? moment(new_date).format('MMM YY') : date 
    }
    else if(date.length === 4 && moment(date).format('YYYY') != "Invalid date"){
        return moment(date).format('YYYY')
    }
        
    return date
    
     
}

export const currentDate = (formate = "YYYY-MM-DD") => {
    return moment(new Date()).format(formate)
}
  
export const array_to_html = (param) => {   
    let txt = ""
    if(param){        
        for(const val of param){
            if(val){
                txt += `<p>${val}</p>`
            }                
        }       
    }
    return txt
}

 
export  const color_recommendation = (data) => {
    let status = {
                'Accepted' : 'green',
                'Recommended' : 'green',
                'Not recommended' : 'red',
                'Not Recommended' : 'red',
                'Unable to recommend' : 'purple',
                'Recommended for use within the CDF' : 'blue',
                'Optimised': 'orange',
                'Optimised (CDF)': 'orange',    
                'Withdrawn' : 'gray',
                'Superseded' : 'gray',
                'Restricted' : 'orange',
                'Reimburse' : 'green',
                'Do not reimburse' : 'red',
                'Reimburse with clinical criteria and/or conditions' : 'orange'
            }
    
    if(status[data]){
        return status[data]
    }else{
        return ""
    }
}

export const color_rembursement = (param) => {
    let classname = ''
    if (param){
       
        let status = {
            'Yes':'green',
            'Positive':'green',
            'No' : 'red',
            'Negative' : 'red',
            'Nagative' : 'red',
            'Partial' : 'orange',
            'Ongoing' : 'ongoing',
            // 'N/A' : 'gray',            
            'Partial (not TA2 list)' : 'orange',
            'Year one': 'green',
            'Suspended':'pause',
        };      
        if (status[param]) {
            classname =  status[param]
        } else {
            classname =  "black"
        }      
    }
    return classname;
}

export const checkEmpty = (param, returnEmpty = "-") => {
    if(param){
        return param
    }
    return returnEmpty
}

export const icer_icur = (value, exceed, less, na, dominated, reference, empty="") => {
    let result = empty
    if(value){
        result = (exceed ? "> " : "")+(less ? "< " : "")+value;
    }else if(na){
        result = "N/A";
    }else if(dominated){
        result = "Dominated";
    }else if(reference){
        result = "Reference";
    }
    return result;
}

export const mergeRow = (idTable, columnArr) => {
    const previousRow = {};

    Array.from(document.querySelectorAll(idTable+' tbody tr')).forEach((tr, rowIdx) => {
        
        let hasColspan = false;
        Array.from(tr.children).forEach((td) => {
            if(td.hasAttribute("colspan")){
                hasColspan = true;
                columnArr.forEach((i) => {
                    previousRow[i] = { span: 1, text: "" };
                })
            }
        });
        Array.from(tr.children).forEach((td, colIdx) => {
            if(columnArr.includes(colIdx) ){
                if (rowIdx > 0 && !hasColspan && previousRow[colIdx] && previousRow[colIdx].text === td.innerText && td.innerText !== "") {
                    previousRow[colIdx].elem.setAttribute('rowspan', ++previousRow[colIdx].span);                            
                    td.remove();
                } else {
                    previousRow[colIdx] = { span: 1, text: td.innerText, elem: td };                            
                }
            }
        });                    
        
    });
}


export const mergeRow_class = (idTable, columnArr) => {
    const previousRow = {};
    Array.from(document.querySelectorAll(idTable)).forEach((table) => {
        Array.from(table.querySelectorAll('tbody tr')).forEach((tr, rowIdx) => {
            
            let hasColspan = false;
            Array.from(tr.children).forEach((td) => {
                if(td.hasAttribute("colspan")){
                    hasColspan = true;
                    columnArr.forEach((i) => {
                        previousRow[i] = { span: 1, text: "" };
                    })
                }
            });
            Array.from(tr.children).forEach((td, colIdx) => {
                if(columnArr.includes(colIdx) ){
                    if (rowIdx > 0 && !hasColspan && previousRow[colIdx] && previousRow[colIdx].text === td.innerText && td.innerText !== "") {
                        previousRow[colIdx].elem.setAttribute('rowspan', ++previousRow[colIdx].span);                            
                        td.remove();
                    } else {
                        previousRow[colIdx] = { span: 1, text: td.innerText, elem: td };                            
                    }
                }
            });                    
            
        });
    });
}

export const base64url_encode = (str) => {
    str = Base64.encode(str);
    str = str.replace('+', '-', str);
    str = str.replace('/', '_', str);
    return str;
}

export const type_file = (param) => {
    if(param){
        const arr = param.split(".");
        return (arr[arr.length - 1]).toLowerCase()
    }    
}