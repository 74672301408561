import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './locales'
import { FontAwesomeIcon } from './plugins/font-awesome'

if(!localStorage.getItem('language')){
    localStorage.setItem('language', 'fr')
}
if(!localStorage.getItem('mode')){
    localStorage.setItem('mode', 'light')
}

createApp(App)
    .use(router)
    .use(store)
    .use(i18n)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')
