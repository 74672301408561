import { createStore } from 'vuex'
import auth from "./auth.module"
import autocomplete from "./autocomplete.module"
import result from "./result.module"
import detail from "../prismaccess/src/store/detail.module"
import translation from "../prismaccess/src/store/translation.module"
import view_selection from "./view_selection.module"
import users from "./users.module"

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    autocomplete,
    result,
    view_selection,
    users,
    detail,
    translation
  }
})
