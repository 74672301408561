const getDefaultState = () => {
    return {
        method : [],
        discussion : [],
        limitation : [],
        table_data : [],
        graph_data : []
    }
}
const state = getDefaultState();

const getters = {};

const actions = {
     
};
const mutations = {
    setTableData(state, param){
        state.table_data = param;
    },
    setGraphData(state, param){
        state.graph_data = param;
    },
    setMethod(state, param){
        state.method = param;
    },
    setDiscussion(state, param){
        state.discussion = param;
    },
    setLimitation(state, param){
        state.limitation = param;
    },
    clearState(state) {
        Object.assign(state, getDefaultState())
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

