import AutocompleteService from '../services/autocomplete.service';
 
const state = {
    name : [],
    therapeutic : [],
    dci : [],
    load : false
};

const getters = {};

const actions = {
    loadAutocomplete({ commit }, keyLoad) {
        commit('setLoad', true);
        return AutocompleteService.getAutocomplete(keyLoad).then(
            res => { 
                commit('GET_AUTOCOMPLETE', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
};
const mutations = {
    GET_AUTOCOMPLETE(state, res) { 
        if(res.name !== undefined){
            state.name = res.name;
        }
        if(res.therapeutic !== undefined){
            state.therapeutic = res.therapeutic;
        }
        if(res.dci !== undefined){
            state.dci = res.dci;
        }
    },
    setLoad(state, load){
        state.load = load;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}