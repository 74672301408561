import { createI18n } from 'vue-i18n'
import langEn from './en.json'
import langFr from './fr.json'
import langDe from './de.json'
import langEnPrismaccess from '../prismaccess/src/locales/en.json'
import langFrPrismaccess from '../prismaccess/src/locales/fr.json'
import langDePrismaccess from '../prismaccess/src/locales/de.json'


const messages = {
    en: {...langEnPrismaccess, ...langEn},
    fr: {...langFrPrismaccess, ...langFr},
    de: {...langDePrismaccess, ...langDe},
  }
 

export default createI18n({
  locale: localStorage.getItem('language'),
  fallbackLocale: localStorage.getItem('language'),
  messages,
  formatFallbackMessages: true,
  silentTranslationWarn: true
})
