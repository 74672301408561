import { createRouter, createWebHistory } from 'vue-router'

import { isLogin } from '@/utils';
 
//lazy-loaded
const Home = () => import("../views/Home.vue")
const Login = () => import("../views/Auth/Login.vue")
const Register = () => import(/* webpackChunkName: "register" */ "../views/Auth/Register.vue")
const Result = () => import(/* webpackChunkName: "result" */ "../views/Result.vue")
const NotFound = () => import(/* webpackChunkName: "notFound" */ "../views/NotFound.vue")
const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/result/:base64Search',
        name: 'result',
        component: Result
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/register",
        name: "register",
        component: Register
    },  
    {
        path: '/oauth/callback',
        name: 'callback',
        component: () => import(/* webpackChunkName: "callback" */ '@/views/Auth/Callback.vue')
    },   
    {
        path: '/detail/:id/:agency',
        name: 'detail', 
        component: () => import(/* webpackChunkName: "detail" */ '@/views/Detail.vue')
    },  
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: "forgot_password" */ '@/views/Auth/ForgotPassword.vue')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "reset_password" */ '@/views/Auth/ResetPassword.vue')
    },    
    { 
        path: "/:catchAll(.*)",
        name: 'NotFound', 
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,

    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
   
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login', '/redirect_from_prismaccess', '/register', '/forgot-password', '/reset-password', '/oauth/callback'];
    const authRequired = !publicPages.includes(to.path);
  
    if (authRequired && !isLogin()) {
        localStorage.setItem('redirectTo', to.path)
        next('/login');
    }else {
        next();
    }
  

})

export default router