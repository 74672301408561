import ResultService from '../services/result.service';

const getDefaultState = () => {
    return {
        count : 0,
        data : {},
        data_chart : {},
        export_columns : [],
        essais_clinique : [],
        base_case : {},
        base_case_table : [],
        base_case_option : {
            therapeutic_area : [],
            name : [],
            icer_range : [0, 0],
            date_range : [null, null]
        },
        base_case_filter : {
            therapeutic_area : "",            
            name : [],
            treatment_line : [],
            icer_range : [0, 0],
            date_range : [null, null]        
        },
        is_base_case_filter : false,
        base_case_modal : null,

        limitation_landscape : {},
        limitation_landscape_table : [],
        limitation_landscape_option_agency : [], 
        limitation_landscape_filter : {
            therapeutic_area : "",            
            name : [],
            limitation : [],
            agency : []                    
        },
        load_excel : false
    }
}

const getDefaultStateBaseCaseCountry = () => {
    return {
        show_base_case_country_chart : false,
        base_case_country : {},
        base_case_country_table : [],
        base_case_country_option : {
            therapeutic_area : [],
            name : [],
            icer_range : [0, 0],
            icer_min : 0,
            icer_max : 0,
            date_range : [null, null],
            date_min : null,
            date_max : null,
            agency_model : null,
            icon_flags : null,
            acronym : null
        },

    }
}

const state = {
    load : false, 
    load_chart_normal : false, 
    load_chart : false, 
    load_chart_sub : false, 
    load_chart2: false,
    ...getDefaultState(), 
    ...getDefaultStateBaseCaseCountry()
}

const getters = {
    getEssaisCliniqueById: (state) => (id) => {
        const temp = state.essais_clinique.find(item => item.id == id)
        return temp ? temp.data : null
    },
    getDataChartByType: (state) => (type) => {
        return state.data_chart[type] ? state.data_chart[type] : null
    },
};

const actions = {
    result({ commit }, payLoad) {
        commit('setLoad', true);
        return ResultService.result(payLoad).then(
            res => {
                commit('result', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    result_chart({ commit }, payLoad) {
        commit('setLoadChartNormal', true);
        return ResultService.result_chart(payLoad).then(
            res => {
                commit('setResultChart', res);
                commit('setLoadChartNormal', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadChartNormal', false);
                return Promise.reject(error);
            }
        )
    },
    result_chart_base_case({ commit }, payLoad) {
        if(!payLoad.table){ commit('setLoadChart', true); }        
        return ResultService.result_chart_base_case(payLoad).then(
            res => {                
                if(!payLoad.table){ 
                    commit('setResultBaseCaseChart', res);
                    commit('setLoadChart', false);
                }else{
                    commit('setResultBaseCaseTable', res);
                }
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadChart', false);
                return Promise.reject(error);
            }
        )
    },
    result_chart_base_case_country({ commit }, payLoad) {
        if(!payLoad.table){ commit('setLoadChartSub', true); }       
        return ResultService.result_chart_base_case_country(payLoad).then(
            res => { 

                if(!payLoad.table){ 
                    commit('setResultBaseCaseCountryChart', res);
                    if(payLoad.init){
                        commit('setResultBaseCaseCountryOptionChart', res)
                    }
                    commit('setLoadChartSub', false);
                }else{
                    commit('setResultBaseCaseCountryTable', res);
                }

                return Promise.resolve(res);
            },
            error => {
                commit('setLoadChart', false);
                return Promise.reject(error);
            }
        )
    },
    result_chart_limitation_landscape({ commit }, payLoad) {
        commit('setLoadChart2', true);    
        return ResultService.result_chart_limitation_landscape(payLoad).then(
            res => {
                commit('setResultLimitationLandscapeChart', res);
                commit('setLoadChart2', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadChart2', false);
                return Promise.reject(error);
            }
        )
    },
    essais_clinique({ commit }, payLoad) {
        
        return ResultService.essais_clinique(payLoad).then(
            res => {
                commit('essais_clinique', res);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    result_excel({ commit }, [payLoad, fileName]) { 
        commit('setLoadExcel', true);
        return ResultService.result_excel(payLoad, fileName).then(
            res => { 
                commit('setLoadExcel', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadExcel', false);
                return Promise.reject(error);
            }
        )
    },
};
const mutations = {
    result(state, res) {
        state.data = res.data;  
        state.count = res.data.total;
        if(state.export_columns.length === 0){
            state.export_columns = res.export_columns;
        }      
    },
    setResultChart(state, res) {
        state.data_chart = res; 
        if(res.option && res.option.therapeutic_area){
            state.base_case_option.therapeutic_area = res.option.therapeutic_area
            state.base_case_filter.therapeutic_area = res.option.therapeutic_area[0];
            state.limitation_landscape_filter.therapeutic_area = res.option.therapeutic_area[0];
        }
        if(res.option && res.option.date_min){
            state.base_case_option.date_range = [new Date(res.option.date_min).getTime() / 1000, new Date(res.option.date_max).getTime() / 1000];
            state.base_case_filter.date_range = [new Date(res.option.date_min).getTime() / 1000, new Date(res.option.date_max).getTime() / 1000];
        }
        if(res.drug){
            const temp = []
            res.drug.forEach(value=>{
                temp.push(value.name);
            });
            state.base_case_option.name = temp
        }        

    },
    setResultBaseCaseTable(state, res) {
        state.base_case_table = res.base_case;
    },
    setResultBaseCaseChart(state, res) {
        state.base_case = res.base_case;
        if(!state.base_case_option.icer_range[0]){
            const temp = []
            res.base_case.exceed.forEach(value=>{
                temp.push(value[1]);
            });
            res.base_case.less.forEach(value=>{
                temp.push(value[1]);
            });
            res.base_case.single.forEach(value=>{
                temp.push(value[1]);
            });
            res.base_case.range.forEach(value=>{
                if(value.length){
                    temp.push(value[0]);
                    temp.push(value[1]);
                }
            });
            state.base_case_option.icer_range = [Math.min(...temp), Math.max(...temp)]
            state.base_case_filter.icer_range = [Math.min(...temp), Math.max(...temp)]
        }
    },
    setShowBaseCaseCountryChart(state, val){
        state.show_base_case_country_chart = val
    },
    setResultBaseCaseCountryChart(state, res){
        state.base_case_country = res.data;
    },
    setResultLimitationLandscapeChart(state, res){
        state.limitation_landscape = { 
            series : res.series,
            categories0 : res.categories0,
            categories1 : res.categories1 
        }
        state.limitation_landscape_table = res.table;
        if(state.limitation_landscape_option_agency.length === 0){
            state.limitation_landscape_option_agency = res.option_agency
        }
    },
    setResultBaseCaseCountryTable(state, res){
        state.base_case_country_table = res.data;
    },
    setResultBaseCaseCountryOptionChart(state, res){
        
            const temp = []
            res.data.exceed.forEach(value=>{
                if(value['y'] > 0){
                    temp.push(value['y']);
                }
            });
            res.data.less.forEach(value=>{
                if(value['y'] > 0){
                    temp.push(value['y']);
                }
            });
            res.data.single.forEach(value=>{
                if(value['y'] > 0){
                    temp.push(value['y']);
                }
            });
            res.data.range.forEach(value=>{
                if(value.high && value.high > 0){
                    temp.push(value['low']);
                    temp.push(value['high']);
                }
            });
            state.base_case_country_option.icer_range = [Math.min(...temp), Math.max(...temp)]
            state.base_case_country_option.icer_max = Math.max(...temp)
            state.base_case_country_option.icer_min = Math.min(...temp)
        

        if(res.option && res.option.therapeutic_area){
            state.base_case_country_option.therapeutic_area = res.option.therapeutic_area
        }
        if(res.option && res.option.date_min){
            state.base_case_country_option.date_min = new Date(res.option.date_min).getTime() / 1000;
            state.base_case_country_option.date_max = new Date(res.option.date_max).getTime() / 1000;
            state.base_case_country_option.date_range = [state.base_case_country_option.date_min, state.base_case_country_option.date_max]
        }
        if(res.option && res.option.name){
            state.base_case_country_option.name = res.option.name
        }
        if(res.agency){
            state.base_case_country_option.agency_model = res.agency.agency_model;
            state.base_case_country_option.icon_flags = res.agency.icon_flags;
            state.base_case_country_option.acronym = res.agency.acronym;
        }
    },
    setLoad(state, load){
        state.load = load;
    },
    setLoadChartNormal(state, load){
        state.load_chart_normal = load;
    },    
    setLoadChart2(state, load){
        state.load_chart2 = load;
    },    
    setLoadChart(state, load){
        state.load_chart = load;
    },
    setLoadChartSub(state, load){
        state.load_chart_sub = load;
    },
    essais_clinique(state, res) {
        state.essais_clinique.push(res);             
    },
    setFilterTherapeuticArea(state, val){
        state.base_case_filter.therapeutic_area = val;
    },
    setFilterTreatmentLine(state, val){
        state.base_case_filter.treatment_line = val;
    },
    setFilterName(state, val){
        state.base_case_filter.name = val;
    },
    setFilterIcerRange(state, val){
        state.base_case_filter.icer_range = val;
    },    
    setFilterDateRange(state, val){
        state.base_case_filter.date_range = val;
    },
    setFilterIcerRangeCountry(state, val){
        state.base_case_country_option.icer_range = val;
    },    
    setFilterDateRangeCountry(state, val){
        state.base_case_country_option.date_range = val;
    },
    setIsBaseCaseFilter(state, val){
        state.is_base_case_filter = val;
    },
    setModalBasecase(state, val){
        state.base_case_modal = val;
    },
    setFilterLimitationLandscapeTherapeuticArea(state, val){
        state.limitation_landscape_filter.therapeutic_area = val;
    }, 
    setFilterLimitationLandscapeName(state, val){
        state.limitation_landscape_filter.name = val;
    }, 
    setFilterLimitationLandscapeLimitation(state, val){
        state.limitation_landscape_filter.limitation = val;
    }, 
    setFilterLimitationLandscapeAgency(state, val){
        state.limitation_landscape_filter.agency = val;
    },
    setLoadExcel(state, load){
        state.load_excel = load;
    },
    clearStateBaseCaseCountry(state) {
        Object.assign(state, {...getDefaultStateBaseCaseCountry()})
    },
    clearState(state) {
        Object.assign(state, {...getDefaultState(), ...getDefaultStateBaseCaseCountry()})
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}