import api from './api'; 
import authHeader from './auth-header';  

class ResultService {
    result(param) { 
        let page = "";
        if('page' in param){
            page = `?page=${param.page}`;
            delete param.page;
        }
        return api
        .post(`result${page}`, param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    result_chart(param) {         
        return api
        .post(`result_chart`, param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    result_chart_base_case(param) {         
        return api
        .post(`result_chart_base_case`, param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    result_chart_base_case_country(param) {         
        return api
        .post(`result_chart_base_case_country`, param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    result_chart_limitation_landscape(param) {         
        return api
        .post(`result_chart_limitation_landscape`, param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    essais_clinique(id) {
        return api
        .get(`essais_clinique/${id}`, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    result_excel(param, fileName) {        
        return api
        .post(`result?export=true`, param, { headers: authHeader(), responseType: 'blob' })
        .then(response => {          
            const file = new Blob([response.data]);
            const url = window.URL.createObjectURL(file)
            const a = document.createElement('a')
            document.body.appendChild(a) 
            a.href = url
            a.download = fileName + ".xlsx"
            a.target = '_blank' 
            a.click()
            a.remove() 
            window.URL.revokeObjectURL(url)
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
        
   
}

export default new ResultService();
