export default function authHeader(headers={}) {
  let token = JSON.parse(localStorage.getItem('token'));
  let language = localStorage.getItem('language');  
  if (token) {
    return {
      ...headers,
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization : `Bearer ${token}`,
      "Content-Language" : language
    };
  } else {
    return {
      ...headers,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Content-Language" : language
    };
  }
}
