<template>
  <div>
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/health.css";
import "./assets/css/style.css";
import "./assets/css/load.css";
import "./assets/css/custom.css";
import "./assets/css/dark.css";
export default {
  
}
</script>

