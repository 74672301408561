import api from './api'; 
import authHeader from './auth-header';
// import router from '../router'
class AuthService {
  register(user) {
    return api
      .post('auth/register', user, { headers: authHeader() })
      .then(response => {
        return response.data;
      })
      .catch( error  => {
        return Promise.reject(error);
      });
  }

  login(user) {
    return api
      .post('auth/login', {
        username: user.username,
        password: user.password,
        remember : user.remember
      })
      .then(response => {      
        if (response.data.token) {
          localStorage.setItem('token', JSON.stringify(response.data.token));          
          localStorage.setItem('user', JSON.stringify(response.data.user));
          localStorage.setItem('language', JSON.stringify(response.data.user.default_language).slice(1,3));
        }
        return response.data.user;
      })
      .catch(error  => {            
        console.log(error);
        return Promise.reject(error);
      });
  }

  login_auto(code) { 
    return api
      .post('auth/login_auto/'+ code.code, {})
      .then(response => {      
        if (response.data.token) {
          localStorage.setItem('token', JSON.stringify(response.data.token));          
          localStorage.setItem('user', JSON.stringify(response.data.user));
          localStorage.setItem('language', JSON.stringify(response.data.user.default_language).slice(1,3));
        }
        return response.data.user;
      })
      .catch(error  => {            
        console.log(error);
        return Promise.reject(error);
      });
  }

  login_oidc(code){
    return api
      .post('auth/login_oidc/'+ code.code)
      .then(response => {      
        localStorage.removeItem('oidc_endpoint');
        if (response.data.token) {
          localStorage.setItem('token', JSON.stringify(response.data.token));          
          localStorage.setItem('user', JSON.stringify(response.data.user));
          localStorage.setItem('language', JSON.stringify(response.data.user.default_language).slice(1,3));
        }
        return response.data.user;
      })
      .catch(error  => {      
        alert("Username or password is incorrect.");
        return Promise.reject(error);
      });
  }

  get_authorize_endpoint(){
    return api
      .get('auth/get_authorize_endpoint')
      .then(response => {  
        localStorage.setItem('oidc_endpoint', response.data.endpoint);
        return response.data.endpoint;
      })
      .catch(error  => {            
        console.log(error);
        return Promise.reject(error);
      });
  }

  logout() {
    return api.post('auth/logout', null, { headers: authHeader() } )
    .then(response => {       
      localStorage.removeItem('token'); 
      localStorage.removeItem('user'); 
      return response;
    })
  } 
  
  forgot_password(email) {
    return api
      .post('auth/forgot-password', email, { headers: authHeader() })
      .then(response => {
        return response.data;
      })
      .catch( error  => {
        return Promise.reject(error);
      });
  }

  reset_password(param) {
    return api
      .post('auth/reset-password', param, { headers: authHeader() })
      .then(response => {
        return response.data;
      })
      .catch( error  => {
        return Promise.reject(error);
      });
  }

  get_domain_names_internal(){
    return api
      .get('auth/get_domain_names_internal')
      .then(response => {  
        return response.data;
      })
      .catch(error  => {            
        console.log(error);
        return Promise.reject(error);
      });
  }

  check_user_oidc(user){
    return api
      .get('auth/check_user_oidc/'+user)
      .then(response => {  
        return response.data.sda_registration_status;
      })
      .catch(error  => {            
        console.log(error);
        return Promise.reject(error);
      });
  }
}

export default new AuthService();
